import { NextPage } from "next";

import { INextPageProps } from "types";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Locales } from "i18n/country";
import PageModularApi from "../contentful/page-modular-api";
import { PageModular } from "../contentful/PageModularModule";
import { observer } from "../app";

import dynamic from "next/dynamic";

import { ModularPageProps } from "domain/CommonModules/ModularPage";
import { EHttpStatus } from "../utils/backend-api/error-types";
import { Toggles } from "../../server/feature-toggles/toggles";
import { useStores } from "../hooks";
import { fetchFeatureToggles } from "utils/featureTogglesUtils";

const ModularPage = dynamic<ModularPageProps>(() =>
  import("domain/CommonModules/ModularPage").then((mod) => mod.ModularPage)
);

const Error = dynamic(() => import("./_error"), { ssr: false });

export interface IProps extends INextPageProps {
  modularStartPageExists?: boolean;
  modularStartPage?: PageModular;
  toggles?: Toggles;
}

const Index: NextPage<IProps> = observer(({ ...props }) => {
  const { modularStartPage, modularStartPageExists } = props;
  const { layoutStore } = useStores();

  if (props.toggles) {
    layoutStore.setToggles(props.toggles);
  }

  if (modularStartPageExists) {
    return <ModularPage modularPage={modularStartPage} />;
  }
  return <Error statusCode={EHttpStatus.NotFound} />;
});

export const getStaticProps = async () => {
  const getModularStartPage = (locale: string): Promise<PageModular> => {
    const homepageSlug = "/";
    return PageModularApi.getInstance().getPageModularBySlug(locale, homepageSlug);
  };

  const locale = Locales.USA;
  const [modularStartPage, sst] = await Promise.all([
    getModularStartPage(locale),
    serverSideTranslations(locale, ["common", "page-index", "pages-record-check", "page-error", "page-pricing"]),
    fetchFeatureToggles(),
  ]);

  const modularStartPageExists = Boolean(modularStartPage);

  return {
    props: {
      ...sst,
      modularStartPage,
      modularStartPageExists,
    },
    revalidate: 60,
  };
};

export default Index;
